.cardLink {
              text-decoration: none;
              color: $body-color;
              background-color: $white;
              border-radius: $border-radius;
              display: block;
              overflow: hidden;
              box-shadow: $box-shadow;
    transition: $transition-base;
              .img {
                position: relative;
  width: 100%;
  overflow: hidden;
  &::before{
    content: "";
    width: 100%;
    padding-top: 66.75%;//高さの比率 ÷ 幅の比率 × 100
    display: block;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: $transition-base;
  }
              }
              .text {
                padding: 20px 0px;
                text-align: center;
              }
              &:hover{
                color: $primary;
                .img{
                  img{
                    transform: scale(1.05);
                  }
                }
              }
            }