@keyframes bubbleAnime {
  0% {
    transform: translateY(0) scale(0.2);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-500%) scale(1);
  }
}
.topLead {
  padding: 90px 0;
  position: relative;
  .bgAnimation {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .bubble {
      background-color: $gray-200;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      /////////////左側の泡
      &.bubble01 {
        width: 9vw;
        height: 9vw;
        bottom: 65%;
        left: 10%;
        animation: bubbleAnime 2.6s ease-out 0.1s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 3vw;
          height: 3vw;
        }
      }
      &.bubble02 {
        width: 6vw;
        height: 6vw;
        bottom: 70%;
        left: 8%;
        animation: bubbleAnime 2.7s ease-out 0.3s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 2vw;
          height: 2vw;
        }
      }
      &.bubble03 {
        width: 3vw;
        height: 3vw;
        bottom: 86%;
        left: 13%;
        animation: bubbleAnime 2.8s ease-out 0.5s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 1vw;
          height: 1vw;
        }
      }
      &.bubble04 {
        width: 7.5vw;
        height: 7.5vw;
        bottom: 70%;
        left: 7%;
        animation: bubbleAnime 2.9s ease-out 0.7s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 2.5vw;
          height: 2.5vw;
        }
      }
      &.bubble05 {
        width: 4.5vw;
        height: 4.5vw;
        bottom: 59%;
        left: 8%;
        animation: bubbleAnime 3s ease-out 0.9s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 1.5vw;
          height: 1.5vw;
        }
      }
      /////////////右側の泡
      &.bubble06 {
        width: 9vw;
        height: 9vw;
        bottom: 25%;
        right: 10%;
        animation: bubbleAnime 2.6s ease-out 0.1s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 3vw;
          height: 3vw;
        }
      }
      &.bubble07 {
        width: 6vw;
        height: 6vw;
        bottom: 30%;
        right: 8%;
        animation: bubbleAnime 2.7s ease-out 0.3s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 2vw;
          height: 2vw;
        }
      }
      &.bubble08 {
        width: 3vw;
        height: 3vw;
        bottom: 36%;
        right: 13%;
        animation: bubbleAnime 2.8s ease-out 0.5s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 1vw;
          height: 1vw;
        }
      }
      &.bubble09 {
        width: 7.5vw;
        height: 7.5vw;
        bottom: 20%;
        right: 7%;
        animation: bubbleAnime 2.9s ease-out 0.7s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 2.5vw;
          height: 2.5vw;
        }
      }
      &.bubble10 {
        width: 4.5vw;
        height: 4.5vw;
        bottom: 19%;
        right: 8%;
        animation: bubbleAnime 3s ease-out 0.9s infinite normal;
        @include media-breakpoint-up(sm) {
          width: 1.5vw;
          height: 1.5vw;
        }
      }
    }
  }
  .inner {
    position: relative;
    z-index: 2;
    padding: 0 6vw;
    display: flex;
    flex-direction: column;
    gap: 60px;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: center;
      gap: 100px;
      max-width: calc(1150px + 12vw);
      margin: 0 auto;
    }
    .img {
      position: relative;
      width: 100%;
      height: 620px;
      @include media-breakpoint-up(sm) {
        flex-basis: 555px;
        height: 775px;
      }
      .img01 {
        position: absolute;
        top: 0;
        left: 0;
        width: 220px;
        @include media-breakpoint-up(sm) {
          width: 300px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .img02 {
        position: absolute;
        top: 200px;
        right: 0;
        width: 220px;
        @include media-breakpoint-up(sm) {
          top: 238px;
          width: 300px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .img03 {
        position: absolute;
        top: 400px;
        left: 0;
        width: 220px;
        @include media-breakpoint-up(sm) {
          top: 475px;
          width: 300px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .img04 {
        position: absolute;
        top: 2em;
        right: 0;
        font-size: 30px;
        font-family: $font-family-en;
        line-height: 1.2;
        opacity: 0.5;
        transform: rotate(90deg);
        transform-origin: center bottom;
        @include media-breakpoint-up(sm) {
          top: 3.5em;
          right: 50px;
        }
      }
    }
    .cont {
      display: flex;
      flex-direction: column;
      gap: 30px;
      @include media-breakpoint-up(sm) {
        flex: 1;
        gap: 50px;
      }
      .title {
        font-size: 24px;
        line-height: 2;
        margin: 0;
        text-align: center;
        @include media-breakpoint-up(sm) {
          font-size: 36px;
          text-align: left;
        }
      }
      .text {
        font-size: 16px;
        line-height: 2.5;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 21px;
        }
        br {
          display: none;
          @include media-breakpoint-up(sm) {
            display: inline;
          }
        }
      }
    }
  }
}
