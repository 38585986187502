.sectionHeader {
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.5rem;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    align-items: center;
  }
  .cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    .title-en {
      font-size: 45px;
      font-family: $font-family-en;
      line-height: 1;
      text-transform: uppercase;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 60px;
      }
    }
    .title-ja {
      font-size: 18px;
      line-height: 1.5;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
  }
  .more {
  }
  &.center {
    align-items: center;
    @include media-breakpoint-up(sm) {
      justify-content: center;
    }
    .cont {
      text-align: center;
      align-items: center;
    }
  }
  &.menucat{
    margin-bottom: 30px;
    .cont {
      .title-en {
        font-size: 36px;
    
        @include media-breakpoint-up(sm) {
          font-size: 45px;
        }
      }
    
      .title-ja {
        font-size: 16px;
    
        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }
      }
    }
  }
}
