@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  font-feature-settings: "palt";
  letter-spacing: 0.1em;
  opacity: 0;
  transition: $transition-base;

  &.wf-active,
  &.loading-delay {
    opacity: 1;
  }
}

img {
  max-width: 100%;
  height: auto;
}

#app {
  overflow-x: hidden;
}

*:focus-visible {
  outline: none !important;
}

$bg-beige: #f5f0e5;
@import "components/header";
@import "components/toggleMenu";
@import "components/hero";
@import "components/topNews";
@import "components/topLead";
@import "components/layout";
@import "components/sectionHeader";
@import "components/secondHeader";
@import "components/headerCover";
@import "components/topMenu";
@import "components/summary";
@import "components/newsList";
@import "components/planModal";
@import "components/planCard";
@import "components/outline";
@import "components/digest";
@import "components/article";
@import "components/coverBgImg";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/menuTable";
@import "components/menuLink";
@import "components/carousels";
@import "components/aboutLogo";
@import "components/aboutImage";
@import "components/formTable";
@import "components/column";
@import "components/midashi1";
@import "components/midashi2";
@import "components/midashi3";
@import "components/midashi4";
@import "components/brandTable";
@import "components/accessMap";
@import "components/reserveBox";
@import "components/ctaReserve";
@import "components/ctaRecruit";
@import "components/flow";
@import "components/faq";
@import "components/catchCopy1";
@import "components/leadCopy1";
@import "components/cardLink";
@import "components/jobArea";
@import "components/lunchMenu";
@import "components/pagination";
@import "components/footer";
@import "components/animation";
@import "components/vif";
