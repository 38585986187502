.reserveBox {
  border: 3px solid $white;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 50px;
  @include media-breakpoint-up(sm) {
    margin: 0 auto 80px;
  }
  .header {
    margin-top: -10px;
    @include media-breakpoint-up(sm) {
      margin-top: -12px;
    }
    .title {
      font-size: 18px;
      margin: 0;
      line-height: 1;
      display: inline-block;
      background-color: $gray-900;
      padding: 0 1rem;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
  }
  .body {
    padding: 1.5rem 1rem 2rem;
    .tel {
      display: inline-flex;
      font-size: 12px;
      align-items: baseline;
      gap: 5px;
      line-height: 1;
      a {
        color: $white;
        text-decoration: none;
      }
      @include media-breakpoint-up(sm) {
        font-size: 21px;
      }
      strong {
        font-size: 24px;
        @include media-breakpoint-up(sm) {
          font-size: 45px;
        }
      }
    }
    .info {
      font-size: 12px;
      margin-top: 5px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
    .snsList {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      @include media-breakpoint-up(sm) {
        gap: 3rem;
      }
      li {
        flex-basis: 40%;
        @include media-breakpoint-up(sm) {
          flex-basis: auto;
        }
        a {
          color: $white;
          text-decoration: none;
          .icon {
          }
          .text {
            font-size: 12px;
            margin-top: 3px;
          }
        }
      }
    }
  }
  &.sns {
    margin-bottom: 0;
    .body {
      padding: 3rem 1rem;
    }
  }
}
