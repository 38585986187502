.ctaRecruit {
  padding: 0 6vw;
  width: 100%;
  height: 420px;
  background-image: url("../img/bg_topRecruit.webp");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  color: $white;
  .sectionHeader {
    margin: 0;
  }
  .text {
    font-size: 15px;
    text-align: center;
    margin: 0;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
    }
  }
}
