.aboutImage {
  position: relative;
  width: 100%;
  overflow: hidden;
  &::before {
    content: "";
    width: 100%;
    padding-top: 133%; //高さの比率 ÷ 幅の比率 × 100
    display: block;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
