.topNews {
  background-color: $gray-900;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @include media-breakpoint-up(sm) {
    width: 45vw;
    min-width: 500px;
    min-height: 70px;
    margin-left: auto;
    margin-top: -70px;
    position: relative;
    z-index: 2;
  }
  .header {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    > .title {
      font-size: 13px;
      margin: 0;
    }
    @include media-breakpoint-up(sm) {
      padding: 20px 1.5rem;
    }
  }
  .body {
    padding: 1.5rem;
    padding-left: 0;
    flex: 1;
    @include media-breakpoint-up(sm) {
      padding: 20px 1.5rem;
      padding-left: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    ul {
      font-size: 13px;
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        a {
          color: $white;
          text-decoration: none;
          @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            gap: 10px 1rem;
            flex-wrap: wrap;
          }
          .date {
          }
          .title {
          }
        }
      }
    }
  }
}
