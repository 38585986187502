.menuLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  text-decoration: none;
  gap: 1rem;
  transition: $transition-base;
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    &::before {
      content: "";
      width: 100%;
      padding-top: 100%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $transition-base;
    }
  }
  .text {
    margin: 0;
  }
  &:hover {
    color: $primary;
    .img {
      img {
        transform: scale(1.05);
      }
    }
  }
}
