:root {
  --swiper-navigation-color: #{$white};
  --swiper-pagination-color: #{$primary};
  --swiper-navigation-size: 25px !important;
}
.swiper {
  .swiperHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 15px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 30px;
    }
    .title {
      margin: 0;
      margin-right: 1em;
    }
    .controller {
      display: flex;
      justify-content: flex-end;
      div {
        margin-left: 5px;
        background-color: $gray-800;
        color: $white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $transition-base;
        border-radius: 50%;
        @include media-breakpoint-up(sm) {
          width: 45px;
          height: 45px;
        }
        &:hover {
          background-color: $body-color;
          color: tint-color($white, 90%);
        }
        &.swiper-button-disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}
.swiper-pagination-fraction {
  font-size: 13px;
}
.carouselThumbnail {
  padding: 10px 0 !important;
  .swiper-slide {
    opacity: 0.5;
    width: 20%;
    @include media-breakpoint-up(sm) {
      width: 12%;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &.swiper-slide-thumb-active {
      opacity: 1;
    }
  }
}
.carouselCentering {
  padding-bottom: 30px !important;
  .swiper-pagination-fraction {
    bottom: 0;
  }
}
.carouselSideScrolling {
  padding-bottom: 30px !important;
  padding-left: 3vw !important;
  .swiperHeader {
    padding-right: 6vw;
  }
  .swiper-slide {
    width: 60%;
    @include media-breakpoint-up(sm) {
      width: 28%;
    }
    @include media-breakpoint-up(xl) {
      width: 20%;
    }
  }
  .swiper-scrollbar {
    left: 0 !important;
  }
}
.carouselCentering {
  .swiper-slide {
    width: 70%;
  }
}
.carouselCover {
  .fraction-pagination {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    text-align: right;
    color: $white;
    font-size: 13px;
    @include media-breakpoint-up(sm) {
      padding: 15px;
    }
  }
  .carouselCoverSingle {
    display: block;
    color: $body-color;
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 50vh;
    @include media-breakpoint-up(sm) {
      height: 60vh;
    }
    @include media-breakpoint-up(lg) {
      height: 75vh;
    }
    .bar {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: tint-color($primary, 90%);
      border-radius: 2px;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $primary;
        transform: scaleX(0);
        transform-origin: left;
        transition: 3s linear;
        border-radius: 2px;
      }
    }
    .bg {
      width: 100%;
      height: 100%;
      z-index: 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cont {
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 0;
      background-color: $white;
      padding: 20px;
      width: calc(100% - 6vw);
      font-size: 14px;
      @include media-breakpoint-up(sm) {
        padding: 30px;
        width: 70%;
      }
      @include media-breakpoint-up(lg) {
        width: 50%;
        font-size: 15px;
      }
    }
  }
  .swiper-slide-active {
    .carouselCoverSingle {
      .bar {
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }
}
