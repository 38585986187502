.hero {
  margin-top: calc(#{$headerHeightSM} / -1);
  position: relative;
  z-index: 1;
  width: 100%;
  height: 75vh;
  color: $white;
  @include media-breakpoint-up(sm) {
    margin-top: calc(#{$headerHeightLG} / -1);
    height: 100vh;
  }
  .bg {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.4);
    }
  }
  .cont {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $headerHeightSM 6vw calc(#{$headerHeightSM} / 2);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 30px;
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
      gap: 45px;
      padding: 90px 6vw;
    }
    .catchCopy {
      margin: 0;
    }
    .catchCopy-en {
      margin: 0;
      font-size: 12px;
      @include media-breakpoint-up(sm) {
        padding-left: 30px;
      }
    }
  }
  .scrollIcon {
    display: none;
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: absolute;
      z-index: 2;
      right: 0;
      top: 50%;
      font-size: 10px;
      text-transform: uppercase;
      transform: rotate(90deg);
      &::after {
        width: 30px;
        height: 1px;
        background-color: $white;
        content: "";
        animation: lineanime 1s linear 0s infinite;
        transform-origin: right bottom;
      }
    }
  }
}
@keyframes lineanime {
  0% {
    // transform: scaleX(0);
    transform: translateX(0) scaleX(1);
    opacity: 1;
  }
  100% {
    // transform: scaleX(1);
    transform: translateX(10%) scaleX(0);
    opacity: 0;
  }
}
