.headerCover {
  margin-bottom: 60px;
  .bg {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: 45%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
      @include media-breakpoint-up(sm) {
        padding-top: 30%; //高さの比率 ÷ 幅の比率 × 100
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  > .cont {
    display: inline-block;
    background-color: $white;
    position: relative;
    margin-top: -50px;
    z-index: 2;
    padding: 24px 6vw 0;
    min-width: 60vw;
    @include media-breakpoint-up(sm) {
      margin-top: -100px;
      padding: 45px 6vw 0;
      min-width: 50vw;
    }
    .sectionHeader {
      margin-bottom: 0;
    }
  }
}
