.secondHeader {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 300px;
  margin-top: calc(#{$headerHeightSM} / -1);
  @include media-breakpoint-up(sm) {
    margin-top: calc(#{$headerHeightLG} / -1);
    height: 510px;
  }
  .bg {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
  }
  .cont {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $headerHeightSM 6vw calc(#{$headerHeightSM} * 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    color: $white;
    @include media-breakpoint-up(sm) {
      padding: $headerHeightLG 6vw;
      align-items: center;
    }
    .title-en {
      font-size: 45px;
      font-family: $font-family-en;
      line-height: 1;
      text-transform: uppercase;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 60px;
      }
    }
    .title-ja {
      font-size: 18px;
      line-height: 1.5;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
  }
  &::after {
    width: calc(100% - 6vw);
    height: calc(#{$headerHeightSM} * 0.6);
    content: "";
    background-color: $white;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 3vw;
    @include media-breakpoint-up(sm) {
      height: calc(#{$headerHeightLG} * 0.6);
    }
  }
}
