.newsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    &:first-child {
      border-top: 1px solid $gray-200;
      position: relative;
      &::before {
        position: absolute;
        top: -1px;
        left: 0;
        width: 50px;
        height: 1px;
        content: "";
        background-color: $primary;
        transition: $transition-base;
      }
    }
    a {
      display: block;
      text-decoration: none;
      border-bottom: 1px solid $gray-200;
      padding: 30px 0;
      position: relative;
      &::before {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 50px;
        height: 1px;
        content: "";
        background-color: $primary;
        transition: $transition-base;
      }
      .date {
        color: $primary;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .title {
        color: $body-color;
        transition: $transition-base;
      }
      &:hover {
        &::before {
          width: 100%;
        }
        .title {
          color: $primary;
        }
      }
    }
  }
}
