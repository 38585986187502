$headerHeightSM: 90px;
$headerHeightLG: 150px;

#header {
  width: 100%;
  height: $headerHeightSM;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: $black;
  color: $white;
  position: relative;
  z-index: 2;

  @include media-breakpoint-up(sm) {
    padding: 40px 6vw;
    padding-right: calc(6vw + 70px + 3vw);
    height: $headerHeightLG;
  }

  .logo {
    img {
      height: 30px;
    }
  }

  .info {
    display: none;

    a {
      color: $white;
      text-decoration: none;
    }

    @include media-breakpoint-up(sm) {
      display: inline-flex;
      font-size: 15px;
      align-items: baseline;
      gap: 5px;

      strong {
        font-size: 30px;
      }
    }
  }
}