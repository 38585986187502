.lunch{
  color: $primary;
}
.lunchMenu {
  gap: 30px 15px;
  @include media-breakpoint-up(sm) {
    gap: 30px 45px;
  }
  .item {
    width: calc((100% - 15px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 0;
    @include media-breakpoint-up(sm) {
      gap: 12px;
      width: calc((100% - 90px) / 3);
    }
    .img {
      img {
      }
    }
    .cont {
      width: 100%;
      .title {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.5;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }
      }
      .price {
        font-family: "din-2014", sans-serif;
        font-size: 12px;
        font-weight: normal;
        line-height: 1.5;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 15px;
        }
      }
      .text {
        font-size: 12px;
      }
    }
  }
}
