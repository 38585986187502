.planCard {
  background-color: $white;
  border-radius: $border-radius;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  color: $body-color;
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius $border-radius 0 0;
    display: block;
    @include media-breakpoint-up(sm) {
      width: 33%;
      border-radius: $border-radius 0 0 $border-radius;
    }
    &::before {
      content: "";
      width: 100%;
      padding-top: 100%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $transition-base;
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  .cont {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    padding: 30px 15px;
    @include media-breakpoint-up(sm) {
      padding: 20px 45px;
      gap: 1.5rem;
    }
    .period {
      margin: 0;
      background: linear-gradient(90deg, #ba2f16 0%, #cc990d 100%);
      display: inline-block;
      padding: 5px 10px;
      color: $white;
    }
    .title {
      margin: 0;
      font-size: 21px;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
    p {
      margin: 0;
    }
  }
}
