// Custom.scss
// Option B: Include parts of Bootstrap

// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "custom/color";
@import "custom/typography";
@import "custom/table";
@import "custom/body";
$transition-base: all 0.6s ease-in-out;
@import "custom/breadcrumb";
@import "custom/border-radius";
@import "custom/link";
@import "custom/ratio";
@import "custom/btn";

// Optional
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
