.planModal {
  background-color: $white;
  border-radius: $border-radius;
  width: 100%;
  pointer-events: auto;
  .swiperHeader {
    border-radius: $border-radius $border-radius 0 0;
    .btnClose {
      position: absolute;
      z-index: 99;
      z-index: 2;
      top: 1rem;
      right: 1rem;
      color: $white;
      font-size: 24px;
      cursor: pointer;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiperHeaderImg {
      position: relative;
      width: 100%;
      overflow: hidden;
      &::before {
        content: "";
        width: 100%;
        padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .header {
    border-radius: $border-radius $border-radius 0 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: 75%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .btnClose {
      position: absolute;
      z-index: 99;
      top: 0;
      right: 0;
      color: $white;
      font-size: 24px;
      cursor: pointer;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .body {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;
    @include media-breakpoint-up(sm) {
      gap: 2rem;
      align-items: center;
    }
    .period {
      margin: 0;
      background: linear-gradient(90deg, #ba2f16 0%, #cc990d 100%);
      display: inline-block;
      padding: 5px 10px;
      color: $white;
    }
    .title {
      margin: 0;
      font-size: 21px;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
        text-align: center;
      }
    }
    p {
      margin: 0;
    }
  }
}
