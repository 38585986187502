.menuTable {
  border-top: 1px solid $border-color;
  th {
  }
  td {
    text-align: right;
    width: 1%;
    white-space: nowrap;
  }
}
